/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, canonical, schema, robots, image, link }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  title = title ? title.replace(/%%sep%%/, '»') : ''
  if(title) title = title.replace(/ » Fintek.pl/, '')

  const metaDescription = description || site.siteMetadata.description

  const metaArray = [
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:site`,
      content: '@fintek_pl',
    },        
  ]

  if(title) metaArray.push({
    name: `twitter:title`,
    content: title,
  })

  if(metaDescription) metaArray.push({
    name: `twitter:description`,
    content: metaDescription,
  })

  if(metaDescription) metaArray.push({
    name: `description`,
    content: metaDescription,
  })

  if(robots) metaArray.push({
    name: `robots`,
    content: robots,
  })     

  if(title) metaArray.push({
    property: `og:title`,
    content: title,
  })

  if(image) metaArray.push({
    property: `og:image`,
    content: image,
  })    

  if(metaDescription) metaArray.push({
    property: `og:description`,
    content: metaDescription,
  })

  return (


    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s » ${site.siteMetadata.title}`}
      link={[
        !robots || !robots.includes('noindex') ? {
          rel: `canonical`,
          href: canonical,
        } : {},    
      ].concat(link).filter((e,i,a)=>e.href !== null)}
      meta={metaArray.concat(meta).filter((e,i,a)=>e.content !== null)}
    >

    {schema && <script type="application/ld+json"> 
        {JSON.stringify(schema)}
      </script>}

    </Helmet>  
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  link: [],
  description: ``,
  schema: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  image: PropTypes.string,
  schema: PropTypes.object,
}

export default SEO
