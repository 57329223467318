import AniLink from "gatsby-plugin-transition-link/AniLink"
import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from 'gatsby';

const Tags = ({ tags, extraTags }) => {
  let { visibleTags } = useStaticQuery(graphql`
    query TagsQuery {
      visibleTags: allMysqlPost(filter: {nofollow: {ne: 1}, indexables: {elemMatch: {is_robots_noindex: {nin: [1]}}}}) {
        distinct(field: postterms___slug)
      }
    }
  `);

  visibleTags.distinct.push(
    'zestawienie-fintechow', 
    'podcasty-sfintechowani'
  );

  const duplicates = [];

  tags.forEach(function(tag,key){
    if(!tag.parent_slug) tags.splice(key, 1);
    duplicates.push(tag.slug)
  })
 
  extraTags.forEach(function(tag,key){
    if(!tag.parent_slug) extraTags.splice(key, 1);
  })


  return (
  

    <ul className={'tags'}>
      {tags.map((tag) => {
        return visibleTags.distinct.includes(tag.slug) ? (
          <li key={tag.slug}>
            <AniLink fade to={(tag.parent_slug && tag.slug !== 'legal' ? `/${tag.parent_slug}` : '')+`/${tag.slug}/`}>#{tag.name}</AniLink>
          </li>
        ) : ''}
      )}     
      
      {extraTags.map((tag) => {
        return !duplicates.includes(tag.slug) && visibleTags.distinct.includes(tag.slug) ? (
          <li key={tag.slug} className={`extra-tag`}>
            <AniLink fade to={(tag.parent_slug && tag.slug !== 'legal' ? `/${tag.parent_slug}` : '')+`/${tag.slug}/`}>#{tag.name}</AniLink>
          </li>
        ) : ''}
      )}      
       
    </ul>
  
  )



}

Tags.propTypes = {
  tags: PropTypes.array,
  extraTags: PropTypes.array,
}

Tags.defaultProps = {
  tags: [],
  extraTags: [],
}

export default Tags
