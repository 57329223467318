import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const Banner = ({ link, children, className }) => {
  if (!link) {
    return children;
  }

  if (link.startsWith('https://fintek.pl') || link.startsWith('/')) {
    return <AniLink cover to={link} className={className}>
      {children}
    </AniLink>
  } 
  
  if (link) {
    return <a href={link} className={className} target="_blank" rel="nofollow noreferrer">
      {children}
    </a>
  }

  return <div className={className}>
    {children}
  </div>
}

export default Banner