
const FormatDate = (date, withTime = true) => {
	
	const time = Date.parse(date);
	// const diff = (new Date() - time) / 1000;
	
	if(!time) return '-';

	const format = {
		day: 'numeric',
		month: 'long',
		year: 'numeric',
	}

	if(withTime) {
		format.hour = 'numeric'
		format.minute = 'numeric'
	} 

	const formatter = new Intl.DateTimeFormat( 'pl', format );
	return formatter.format( time ); 

	// if(diff / 60 / 60 / 24 / 365 > 1) {
	// 	return Math.floor(diff / 60 / 60 / 24 / 365 > 1) + ' lat temu';
	// }
	
	// if(diff / 60 / 60 / 24 / 30 > 1) {
	// 	return Math.floor(diff / 60 / 60 / 24 / 30) + ' mies. temu';
	// }
	
	// if(diff / 60 / 60 / 24 > 1) {
	// 	return Math.floor(diff / 60 / 60 / 24) + ' dni temu';
	// }

	// if(diff / 60 / 60 > 1) {
	// 	return Math.floor(diff / 60 / 60 > 1) + ' godz temu';
	// }	
	
	// if(diff / 60 > 1) { 
	// 	return Math.floor(diff / 60 / 60 > 1) + ' min temu';
	// }		
	
}

export default FormatDate
