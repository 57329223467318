import bootstrap from "./bootstrap.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import FormatDate from "../utils/format-date.js"
import { Scrollbars } from 'react-custom-scrollbars';
import SEO from "../components/seo"

export default class SearchModule extends React.Component {

	constructor(props) {
		super(props);

		this.mode = this.props.mode
		this.s = this.props.s

		this.timer = null

		this.state = { 
			results: [],
			opened: false,
			searchString: '',
			loading: false,
			value: null
		};

		this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
	}

	searchInput(string) {
		clearTimeout(this.timer);
		this.setState({value: string})
		this.timer = setTimeout(() => this.doSearch(string), 700);
	}


	doSearch(string) {

		if(string.length > 2) { 
			this.setState({loading: true})
			fetch(`https://cms.fintek.pl/api/search.json?s=` + string)
			.then(response => response.json())
			.then(resultData => {
				this.setState({
					searchString: string,
					results: resultData,
					opened: resultData.length > 0,
					loading: false
				})
			})
		} else {
			this.setState({opened: false})
		}
	}

	render() {
		
		const { opened, searchString, results, loading, value } = this.state
		
		if(!value && this.s) {
			this.setState({value: this.s})
			this.doSearch(this.s)
		}

		return (

			<>

			{this.mode !== 'simple' && <SEO title={`Szukaj`} canonical={`https://fintek.pl/szukaj/`} robots={`noindex,nofollow`} />}

			<div ref={this.wrapperRef} className={`search-component ` + (opened ? `search-component-opened` : `search-component-closed`)}>

				<form action="/szukaj" className={`search-component-form ` + bootstrap.mb3}>
					<input type="search" defaultValue={value} onInput={(e) => this.searchInput(e.target.value)} name="s" placeholder={`Szukana fraza`} className={bootstrap.formControl+` form-control font-serif-light`} />
					<button className={bootstrap.btn + ` search-component-btn`} type="submit">
						<FontAwesomeIcon icon={faSearch} spin={loading} /> 
					</button>
				</form>

				{this.mode !== 'simple' && searchString.length > 0 && <p className={'font-serif-light ' + bootstrap.mb3}>Wyniki wyszukiwania dla <strong>{searchString}</strong>:</p>}

				{this.mode === 'simple' ? 
					<div className={'search-component-results'}>
						<Scrollbars style={{ height: 300}}>
							<div className={bootstrap.pLg4 + ' ' + bootstrap.p3 + ' font-serif-bold ' + bootstrap.pb1 + ' ' + bootstrap.small}>
							{results.map((node) => (
								<div key={node.slug} className={bootstrap.mb3}> 
									<AniLink to={`/${node.slug}/`}>{node.title}</AniLink>
								</div>
							))}   	
							</div>
						</Scrollbars>
					</div>
				:

					<div>
					{results.map((node) => (
						<div key={node.slug} className={bootstrap.mb4}> 
							<AniLink to={`/${node.slug}`} className={bootstrap.mb1 + ' h3 ' + bootstrap.h3 + ' ' + bootstrap.dBlock}>{node.title}</AniLink>
							<div className={bootstrap.small + ' ' + bootstrap.mb1}><span className={'color-primary'}>https://fintek.pl/{node.slug}/</span> &raquo; {FormatDate(node.date)}</div>
							<div dangerouslySetInnerHTML={{ __html: node.excerpt.html }} className={bootstrap.small} />
						</div>
					))}  
					</div>
				}	

			</div>

			</>
		)
	}

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
			this.setState({opened: false})
        }
	}
	
}
