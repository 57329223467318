import PropTypes from "prop-types"
import bootstrap from "./bootstrap.module.css"
import "./layout.css"
import Tags from "../components/tags.js"
import Banner from "../components/banner.js"
import SearchModule from "../components/search"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const Layout = ({ children, tags, extraTags, isHome, bottom, className }) => {
	const { mainBanner, podcastsPosts } = useStaticQuery(graphql`
		query Banners {
			mainBanner: mysqlBanner(position: {eq: "header-center"}) {
				anchor
				mysqlImage {
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}

			podcastsPosts: allMysqlPost(limit: 5, sort: {fields: post_date, order: DESC}, filter: {postterms: {elemMatch: {parent_slug: {eq: "podcasty"}, post: {}}}}) {
				edges {
					node {
						post_name
						post_title
						postterms {
							slug
							parent_slug
							name
						}
					}       
				}
			}   			
		}
	`)

	const [ menuOpen, setMenuOpen ] = useState(false)
	const [ searchOpen, setSearchOpen ] = useState(false)

	const breakpoints = useBreakpoint();

  return (
    <>
	    <div className={[bootstrap.containerFluid,(tags || extraTags ? ' has-tags' : ''), 'bg-light', className].join(' ')} id={`main`}>
		    <div className={bootstrap.row}> 
		    	<div className={[bootstrap.colXl2, bootstrap.positionRelative, bootstrap.colLg3, bootstrap.pxLg2, bootstrap.bgWhite].join(' ')}> 
		    		<div className={['header',(searchOpen ? 'search-open' : 'search-closed'),(menuOpen ? 'menu-open' : 'menu-closed')].join(' ')}>
							<header className={bootstrap.p3 + ' ' + bootstrap.pxLg0}>
								<button aria-label="Menu" className={'header-toggle'} onClick={() => setMenuOpen(!menuOpen)}><span></span><span></span><span></span></button>
								<button aria-label="Szukaj" className={'header-toggle header-toggle-search'} onClick={() => setSearchOpen(!searchOpen)}><FontAwesomeIcon size="lg" icon={faSearch} /></button>

								<AniLink cover direction="down" to="/">
									<img className={'header-logo'} src="/assets/logo-fintek.svg" alt="Fintek.pl" />{' '}
								</AniLink>
								
								<div id={`reader`} className={bootstrap.dLgNone}></div>

								<nav id={'menu'}>
									<ul className={bootstrap.ptLg5+' '+bootstrap.pt3+' '+bootstrap.m0+' '+bootstrap.px0}> 
										<li><AniLink cover to="/aktualnosci/">Aktualności</AniLink></li>
										<li><AniLink cover to="/aktualnosci/bankowosc/">Bankowość</AniLink></li>
										<li><AniLink cover to="/wydarzenia/">Eventy</AniLink></li>
										<li><AniLink cover to="/felietony/">Felietony</AniLink></li>
										<li><AniLink cover to="/wywiady/">Wywiady</AniLink></li>
										<li><AniLink cover to="/legal/">Legal</AniLink></li>
										<li><AniLink cover to="/podcasty/">Podcasty</AniLink></li>
										<li><AniLink cover to="/fintek-extra/">Fintek<sup>extra</sup></AniLink></li>
										<li><AniLink cover to="/aktualnosci/podsumowania-i-prognozy-2024/">Okiem eksperta</AniLink></li>
										<li style={{ display: 'flex', paddingTop: '8px' }}>
											<AniLink cover to="/aktualnosci/sztuczna-inteligencja-i-regulowanie-innowacji/" style={{ display: 'flex', flex: '1 1', marginTop: '-8px' }}>
												Sztuczna inteligencja i&nbsp;regulowanie innowacji
												<span style={{ borderLeft: '1px solid #b3b3b3', paddingLeft: '12px', marginLeft: '5px' }} className={[bootstrap.dFlex, bootstrap.alignItemsCenter].join(' ')}>
													<img width="39" height="44" src="/assets/ey.svg" alt="" />
												</span>
											</AniLink>
										</li>
									</ul>
								</nav>
							</header>

							<SearchModule mode={'simple'}></SearchModule>

							<div className={`social-icons`}>						
								<a href={"https://www.linkedin.com/company/fintek.pl"} target="_blank" rel="nofollow noreferrer" className={bootstrap.btn+' btn '+bootstrap.btnSm}>
									<FontAwesomeIcon icon={faLinkedinIn} fixedWidth />
								</a>
								<a href={"https://twitter.com/Fintek_pl"} target="_blank" rel="nofollow noreferrer" className={bootstrap.btn+' btn '+bootstrap.btnSm}>
									<svg className="svg-inline--fa" viewBox="0 0 24 24" ariaHidden="true"><g><path fill="currentColor" d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path></g></svg>
								</a>	
								<a href={"https://web.facebook.com/Fintekpl-1745562259033061/"} target="_blank" rel="nofollow noreferrer" className={bootstrap.btn+' btn '+bootstrap.btnSm}>
									<FontAwesomeIcon icon={faFacebookF} fixedWidth />
								</a>
								<a href={"https://www.youtube.com/channel/UCThB_tc44DGaGhzSMW_JZdg"} target="_blank" rel="nofollow noreferrer" className={bootstrap.btn+' btn '+bootstrap.btnSm}>
									<FontAwesomeIcon icon={faYoutube} fixedWidth />
								</a>														
							</div>
		    		</div>
		    	</div>
		    		
		    	<div className={[bootstrap.colXl10,bootstrap.colLg9,bootstrap.py4].join(' ')}>
		    		<main className={[bootstrap.prLg4].join(' ')}>
							<div className={bootstrap.row}> 
								<div className={[bootstrap.colLg8, bootstrap.colXl9, bootstrap.mb5].join(' ')}> 
									{isHome && mainBanner && mainBanner.mysqlImage && <div className={[bootstrap.dFlex].join(' ')}>
										<div className={[bootstrap.col1,bootstrap.dNone,bootstrap.alignItemsCenter,bootstrap.dMdFlex,bootstrap.flexColumn,bootstrap.py5,'gap3','line-row'].join(' ')}></div>
										<div className={[bootstrap.flexFill].join(' ')}>
											<div className={['banner-top'].join(' ')}>
												<Banner link={mainBanner.anchor} className={[bootstrap.dBlock,bootstrap.mb4].join(' ')}>
													<Img className={[bootstrap.imgFluid,bootstrap.myLg0,bootstrap.w100].join(' ')} fluid={mainBanner.mysqlImage.childImageSharp.fluid} />
												</Banner>
											</div>
										</div>
									</div>}

									{children}
								</div>
						
								<div className={bootstrap.colLg4 + ' ' + bootstrap.colXl3}>
									<div className={['card',bootstrap.mb5,bootstrap.p4].join(' ')}>
										<b>PARTNERZY:</b>

										<div className="fintek-partners">
											<div className="fintek-partner">
												<img width="110" height="37" src="/assets/partners/loando.png" alt="Loando" />
											</div>

											<div className="fintek-partner">
												<img width="140" height="27" src="/assets/partners/walutomat.png" alt="Walutomat" />
											</div>
										</div>

										<div className={[bootstrap.pt2].join(' ')}>
											{ !breakpoints.sm ? <div>
												<strong className={[bootstrap.textUppercase,bootstrap.dBlock,bootstrap.mb3].join(' ')}>
													<img src="/assets/ico-podcasts-small.svg" alt="Ikona podcasty" className={bootstrap.mr2} />
													Ostatnie podcasty
												</strong>
												{podcastsPosts.edges.map(({ node }, id) => (
												<div key={id} className={['border',bootstrap.mb3,bootstrap.dFlex].join(' ')}>
													<div className={['bg-primary-light',bootstrap.dFlex,bootstrap.alignItemsCenter,bootstrap.px2,'text-primary'].join(' ')}>▶</div>
													<AniLink fade to={`/${node.post_name}/`} className={[bootstrap.px3,bootstrap.py2].join(' ')}>
													<Tags tags={node.postterms.filter((el) => el.parent_slug === 'podcasty')} />
													<span className={[bootstrap.dBlock,bootstrap.textDark].join(' ')}>{node.post_title}</span>
													</AniLink>
												</div>	
												))}  

												<AniLink cover to="/podcasty/" className={['btn-primary-gradient',bootstrap.btn,bootstrap.btnBlock,bootstrap.py2].join(' ')}>Podcasty</AniLink>
											</div> : null }
										</div>

									</div>
								</div> 

								{bottom && <div className={[bootstrap.col12].join(' ')}>{bottom}</div>}

							</div>  
						</main>
	
						<hr className={'bg-primary'} />

						<footer>
							<ul className={['font-serif-bold',bootstrap.py3,bootstrap.textCenter].join(' ')}>
								<li><AniLink cover to="/o-nas/">O nas</AniLink></li>
								<li><AniLink cover to="/praca/">Praca</AniLink></li>
								<li><AniLink cover to="/regulamin/" rel="nofollow">Regulamin</AniLink></li>
								<li><AniLink cover to="/reklama/">Reklama</AniLink></li>
								<li><AniLink cover to="/polityka-cookies/" rel="nofollow">Polityka prywatności</AniLink></li>
							</ul>


							<p className={bootstrap.textCenter+' '+bootstrap.small+' '+bootstrap.p4+' font-serif-light'}><span title={new Date()}>{new Date().getFullYear()}</span> © fintek.pl <span className={'color-primary'}>/</span> stworzone przez <a href="https://cormo.pl/">Cormo</a></p>
						</footer>	
		    	</div>
		    </div>
		</div>    

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
